import React, { useRef, useEffect, useState, memo } from "react";
import useSize from "@react-hook/size"; // npm i @react-hook/size
import loadable from "@loadable/component";

const Parser = loadable(() => import("../parser.js"));

import "../../styles/blocks/slideshow.scss";

/**
 * @fuction GalleryCarousel - Renvoie le composant carousel.
 * @param {object} props - Props du composant.
 * 
 * @description 
 * - {string} [id] - id du carousel. 
 * - {string} [className] - classe(s) du carousel. 
 * - {number||false} fixedImageNumber - Nombre d'images affichées peut import la taille du carousel. Si false, carousel adaptatif.
 * - {boolean} openingModal - Determine si le click déclenche l'ouverture de la modal. 
 * - {string} buttonType - Determine le type de boutton du carousel (flèches/bulles).
 * - {number} activeSlide - Determine l'index actif des images du carousel.
 * - {function} setActiveSlide - Setter activeSlide.
 * - {array} innerBlocks - Ensemble des images du carousel.
 * - {boolean} isOpen - Détermine si la modal est ouverte/fermée.
 * - {function} setIsOpen - Setter isOpen.
 * 
 * @returns JSX
 */
const Slideshow = (props) => {
  const {
    attributes,
    innerBlocks,
    anchor
  } = props;

  const align = (props.attributes.align && props.attributes.align.length) ? "align"+props.attributes.align:undefined;
  const className = attributes.className || "";
  const buttonType = attributes.buttonType || "arrow";
  const isAutomatic = attributes.isAutomatic;
  /**
   * @param {useRef} carouselRef
   * @description Permet de récupérer la longueur du carousel et effectuer des calculs.
   */
  const carouselRef = useRef();
  const ghostImage = useRef();
  const autoMove = useRef();
  const maxImg = 1;
  /**
   * @param {useSize} width
   * @description Taille du carousel
   */
  const [width] = useSize(carouselRef);
  const [hasAutoMove, setAutoMove] = useState(true); 
  const [speed, setSpeed] = useState(1000); 
  const [transitionSpeed, setTransitionSpeed] = useState(500); 
  const [activeSlide, setActiveSlide] = useState(0);
  const [lastSlide, setLastSlide] = useState("slide-first");
  let mouseX = -1;
  /**
   * @function goTo - Modifie la valeur de l'image active en fonction du mouvement du carousel.
   * 
   * @param {string} where - Détermine où doit se diriger le carousel.
   * 
   * @description 
   * - Suppression des evenements par défault (retour haut de page, bas de page).
   * - "begin" - Renvoie au début du carousel.
   * - "end" - Renvoie à la fin du carousel.
   * - "next" - Slide suivant || début du carousel.
   * - "previous" - Slide précédente || fin du carousel.
   * 
   * @returns void
   */
  const goTo = (where) => {
    let slide = false;
    if (where === "begin") {  
      slide = 0;
    } else if (where === "end") {
      slide = innerBlocks.length-1;
    } else if (where === "previous") {
      if (activeSlide - maxImg >= 0) {
        slide = activeSlide - maxImg;
      } else {
        slide = innerBlocks.length-1;
      }
    } else if (where === "next") {
      if (activeSlide < innerBlocks.length - maxImg) {
        slide = activeSlide + maxImg;
      } else {
        slide = 0;
      }
    }
    if (slide !== false) {
      setActiveSlide(slide); 
      setTimeout(() => {
        if (slide === 0) {
          setLastSlide("slide-first");
        } else if (slide === innerBlocks.length-1) {
          setLastSlide("slide-last");
        } else {
          setLastSlide("slide-"+slide);
        }
      }, transitionSpeed);
    }
  };

  /**
   * @function handleKeyboard - Appliquer une action en fonction de la touche du clavier ciblée.
   * @param {event} event - Evènenement clavier.
   * @description
   * - [35] : Aller directement à la fin du carousel (touche [fin]).
   * - [36] : Aller directement au début du carousel (touche ↖)
   * - [37] : Aller d'une slide à gauche (touche ⬅)
   * - [39] : Aller d'une slide à gauche (touche ➡)
   * 
   * @returns void
   */
  const handleKeyboard = (event) => {
    if (event.keyCode == 35) {
      goTo("end");
    } else if (event.keyCode == 36) {
      goTo("begin");
    } else if (event.keyCode == 37) {
      goTo("previous");
    } else if (event.keyCode == 39) {
      goTo("next");
    }
  };

  const setMouseX = (value) => {
    if (typeof value === "function") {
      mouseX = value(mouseX);
      return mouseX;
    }
    mouseX = value;
    return mouseX;
  };

  const dragStartHandler = (e) => {
    if (e.target.nodeName.toLowerCase() === "button") {
      return;
    }
    try {
      setMouseX(e.pageX || e.touches[0].pageX);
      e.dataTransfer.setDragImage(ghostImage.current, 0, 0);
    } catch(error) {
      console.error("drag start failed", error); //eslint-disable-line
      console.error(e); //eslint-disable-line
    }
  };

  const dragEndHandler = (e) => {
    if (e.target.nodeName.toLowerCase() === "button") {
      return;
    }
    e.preventDefault();
    setMouseX(oldX => {
      try {
        if (oldX !== -1) {
          let delta = (e.pageX || e.changedTouches[0].pageX) - oldX;
          if (delta > 0) {
            goTo("previous");
          } else {
            goTo("next");
          }
        }
      } catch (error) {
        console.error("drag end failed", error); //eslint-disable-line
        console.error(e); //eslint-disable-line
      }
      return -1;
    });
  };

  if (typeof window !== "undefined") {
    useEffect(() => {
      if (carouselRef.current && width) {
        const speed = parseInt(getComputedStyle(carouselRef.current).getPropertyValue("--speed") || 1000);
        setSpeed(speed);
        const trSpeed = parseInt(getComputedStyle(carouselRef.current).getPropertyValue("--transition-speed") || 1000);
        setTransitionSpeed(trSpeed);
      }
    }, [carouselRef, width]);

    useEffect(() => {
      if (isAutomatic && hasAutoMove) {
        const interval = setInterval(() => {
          goTo("next");
        }, speed);
        autoMove.current = interval;
        return () => {
          try {
            clearInterval(autoMove.current);
          } catch {
            console.log("couldn't stop automove", autoMove); //eslint-disable-line
          }
        };
      }
      return () => {};
    }, [isAutomatic, autoMove, activeSlide, maxImg, hasAutoMove]);
  }

  const InnerContent = memo(() => {
    return <>
      { 
        innerBlocks.map((block, i) => {
          return (<div
            key={i}
            className="slide-wrapper"
          >
            <Parser content={ block.innerBlocks.map(e => e.innerHTML).join("") } />
          </div>);
        }) 
      }
    </>;
  });

  return (
    <div
      ref={ carouselRef }
      tabIndex={1}
      id={ anchor }
      className={ className+" gco-slideshow "+buttonType+` ${align}` }
      style={ { 
        ...(props.style || {}),
      } }
      onClick={() => {
        setAutoMove(false);
        try {
          clearInterval(autoMove.current);
        } catch {
          console.log("couldn't stop automove", autoMove); //eslint-disable-line
        }
      }}
      onKeyDown={ handleKeyboard }
      draggable={ true }
      onDragStart={ dragStartHandler }
      onDragEnd={ dragEndHandler }
      onTouchStart={ dragStartHandler }
      onTouchEnd={ dragEndHandler }
    >
      <img
        ref={ ghostImage }
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TS0VaHAwo4pChOlkQFemoVShChVArtOpgPvoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxdHJSdJES/5cUWsR4cNyPd/ced+8ArllVNKtnAtB028ykkkIuvyqEXxHCIKLgkZAUy5gTxTR8x9c9Amy9i7Ms/3N/jqhasBQgIBDPKoZpE28Qz2zaBuN9Yl4pSyrxOfG4SRckfmS67PEb45LLHMvkzWxmnpgnFkpdLHexUjY14mnimKrplM/lPFYZbzHWqnWlfU/2wkhBX1lmOs0RpLCIJYgQIKOOCqqwEadVJ8VChvaTPv5h1y+SSyZXBQo5FlCDBsn1g/3B726t4tSklxRJAqEXx/kYBcK7QKvhON/HjtM6AYLPwJXe8deaQOKT9EZHix0B/dvAxXVHk/eAyx1g6MmQTMmVgjS5YhF4P6NvygMDt0Dfmtdbex+nD0CWukrfAAeHwFiJstd93t3b3du/Z9r9/QCTXHK0RoF3cwAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+YFFw0VGVLhb+4AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAC0lEQVQI12NgAAIAAAUAAeImBZsAAAAASUVORK5CYII="
        style={{
          top: "-100vh",
          left: "-100vw",
          position:"absolute",
        }}
        aria-hidden="true"
      />
      <div
        className={ "translate "+(activeSlide === 0 ? "first":"")+" "+(activeSlide === innerBlocks.length-1 ? "last":"")+" "+lastSlide }
        style={ { "--nieme-image": activeSlide, "--total": innerBlocks.length } }
      >
        <InnerContent />
      </div>
      { (buttonType === "arrow")
        ? (<><button 
          className="previous"
          title="Previous"
          onClick={ () => goTo("previous") }
        ><span className="sr-only">Précédent</span></button>
        <button 
          title="Next"
          className="next"
          onClick={ () => goTo("next")}
        ><span className="sr-only">Suivant</span></button></>)
        : (<></>)
      }
      {
        ( (buttonType === "button") && (innerBlocks.length > 1) )
          ? (<div 
            className="boutons-container"
          >
            {innerBlocks.map((e,i) => {
              if(i % maxImg == 0) {
                return (<button 
                  key={ `bouton-${i}` } 
                  className={ ((i/activeSlide === 1)) || ((i === 0) && (activeSlide === 0)) ? " bouton-actif " : "" }
                  onClick={ () => { setActiveSlide(i); } }
                ></button>);
              }
            }) } 
          </div>)
          : (<></>)
      }
    </div>
  );
};

export default Slideshow;
